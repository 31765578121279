import React from "react";
import GlobalWrapper from "./src/components/_global/layout/GlobalWrapper";
import "./src/fonts/fonts.css"
import "./src/components/_global/cookiesBanner/cookies.css";

export const wrapRootElement = ({ element }) => {
	return (
		<GlobalWrapper>
			{element}
		</GlobalWrapper>
	);
};
