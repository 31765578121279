import React from "react";
import Theme from "../../../style/Theme";
import GlobalStyle from "../../../style/GlobalStyle";
import { LanguageProvider } from "../../../contexts/LanguageContext";
import { CookiesProvider } from "react-cookie";

const GlobalWrapper = (props) => {
	return (
		<Theme>
			<LanguageProvider>
				<GlobalStyle />
				<CookiesProvider>
					{props.children}
				</CookiesProvider>
			</LanguageProvider>
		</Theme>
	);
};

export default GlobalWrapper;
