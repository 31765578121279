import { createGlobalStyle } from "styled-components";
import reset from "./reset";

const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    ::selection {
      background-color: ${({ theme }) => theme.colors.accent};
      color: ${({ theme }) => theme.colors.background};
    }
  }

  html {
    //height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    //overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts[theme.text.standard.family].family}, sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    //height: 100%;
  }

  #___gatsby {
    width: 100%;
    //height: 100%;
  }

  #gatsby-focus-wrapper {
    width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1, h3 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  p {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text};

    :hover {
      color: ${({ theme }) => theme.colors.main02};
    }
  }

  .contentLink {
    text-decoration: underline;
  }

  *:focus {
    outline: 0 !important;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  // Tamaro Colors
  #rnw-widget-container {
    :root {
    }
  }

  #tamaro-widget.tamaro-widget {
    background-color: red;
  }

  //ul {
  //  list-style-type: disc;
  //  list-style-position: inside;
  //}
  //ol {
  //  list-style-type: decimal;
  //  list-style-position: inside;
  //}
  //ul ul, ol ul {
  //  list-style-type: circle;
  //  list-style-position: inside;
  //  margin-left: 15px;
  //}
  //ol ol, ul ol {
  //  list-style-type: lower-latin;
  //  list-style-position: inside;
  //  margin-left: 15px;
  //}
`;

export default GlobalStyle;
