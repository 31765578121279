import { ThemeProvider } from "styled-components";
import React from "react";

const Theme = (props) => {
	const theme = {
		colors: {
			grey: "#272727",
			lightGrey: "#e8e8e8",
			guardsmanRed: "#C50000",
			chablis: "#FFF3F3",
			white: "#FFFFFF",
			black: "#01081A",

			congressBlue: "#04417C",
			pictonBlue: "#3BB2F5",
			steelBlue: "#5F8CC0",
			periwinkleGray: "#CACAE5",
			titanWhite: "#FAFAFF",
			california: "#F49404",


			get main01() {
				return this.congressBlue;
			},
			get main02() {
				return this.pictonBlue;
			},
			get contrast() {
				return this.california;
			},
			get error() {
				return this.guardsmanRed;
			},
			get errorPale() {
				return this.chablis;
			},

			get text() {
				return this.black;
			},
			get background() {
				return this.white;
			},
			get accent() {
				return this.pictonBlue;
			}
		},

		fonts: {
			Rubik: {
				family: "Rubik",

				// weights
				regular: "400",
				medium: "500",
				bold: "600"
			}
		},

		text: {
			standard: {
				family: "Rubik",
				size: {
					regular: "1.1rem", // 32px
					small: "1rem" // 16px
				},
				weight: "400",
				lineHeight: "auto",
				color: "text",
				transform: "none"
			},
			h1: {
				family: "Rubik",
				size: {
					regular: "2.5rem", // 40px
					small: "1.5rem" // 24px
				},
				weight: "500",
				lineHeight: {
					regular: "125%",
					small: "125%"
				},
				color: "text",
				transform: "none"
			},
			h2: {
				family: "Rubik",
				size: {
					regular: "1.5625rem", // 25px
					small: "1.125rem" // 18px
				},
				weight: "500",
				lineHeight: "150%",
				color: "text",
				transform: "none"
			},
			medium: {
				family: "Rubik",
				size: {
					regular: "1.375rem", // 22px
					small: "1rem" // 16px
				},
				weight: "normal",
				lineHeight: "150%",
				color: "text",
				transform: "none"
			},
			smaller: {
				family: "Rubik",
				size: {
					regular: "1rem", // 16px
					small: "0.875rem" // 14px
				},
				weight: "normal",
				lineHeight: "auto",
				color: "text",
				transform: "none"
			},
			buttonSmall: {
				family: "Rubik",
				size: {
					regular: "1.125rem", // 18px
					small: "0.875rem" // 14px
				},
				weight: "normal",
				lineHeight: "auto",
				color: "text",
				transform: "none"
			},
			title: {
				family: "Rubik",
				size: {
					regular: "3.125rem", // 50px
					small: "1.875rem" // 30px
				},
				weight: "400",
				lineHeight: "auto",
				color: "text",
				transform: "uppercase"
			}
		},

		sizes: {
			globalWidth: "73rem",
			globalMargins: "3.125rem", // 50px
			headerHeight: "4rem",
			headerHeightMobile: "3rem",
			maxWidth: "85rem"
		},

		margins: {
			small: { l: "3.125rem", s: "1rem" }, // 50px | 16px
			medium: { l: "9.375rem", s: "1rem" }, // 150px |
			large: { l: "12.5rem", s: "2.5rem" } // 200px | 40px
		},

		breakPoints: {
			xxs: "20rem", // 320px
			xs: "26.563rem", // 425px
			s: "48rem", // 768px
			m: "64rem", // 1024px
			l: "83.125rem" // 1330px
		},

		spacer: {
			smallest: ["0.25rem", "0.25rem"],
			smaller: ["0.5rem", "0.5rem"],
			small: ["1rem", "0.5rem"],
			medium: ["2rem", "1rem"],
			large: ["4rem", "2rem"],
			larger: ["8rem", "4rem"]
		},

		paddings: {
			left: "2rem",
			right: "2rem"
		},

		radii: {
			standard: "1.25rem",
			buttonLink: "0.375rem",
			tile: "1rem"
		},

		filters: {
			cardDropShadow: "drop-shadow(-5px 3px 10px rgba(24, 91, 224, 0.25))",
			titleDropShadow: "drop-shadow(0px 0px 10px rgba(24, 91, 224, 0.25))"
		},

		time: {
			date: "DD.MM.YYYY",
			dateTime: "DD.MM.YYYY - HH:mm"
		}
	};

	return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default Theme;
